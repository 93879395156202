import { createRef, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { useOnClickOutside } from '../hooks'

const tripClassItems = [
  { id: 'premium', value: 'premium', label: 'Premium class' },
  { id: 'business', value: 'business', label: 'Business class' },
  { id: 'first', value: 'first', label: 'First class' }
]

export const TripClassSelector = ({ control, name }) => {
  const ref = createRef()
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <div
      class="w-full md:max-w-2xl lg:max-w-3xl xl:max-w-6xl mb-10">
      <div class="flex flex-col items-center">
        <div
          class="uppercase text-white text-sm tracking-widest font-bold"
          data-splitting=""
        >
          THINK COMFORT
        </div>

        <div class="hidden lg:flex lg:items-center mt-4">
          <StyledBackdrop isOpen={isOpen} />
          <div class="dropdown">
            <div class="dropdown__wrapper" ref={ref}>
              <StyledBackground isOpen={isOpen} />

              <button
                class="dropdown__caption"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Controller
                  control={control}
                  name={name}
                  render={({ value }) => (
                    <StyledLabel isOpen={isOpen}>
                      {value.charAt(0).toUpperCase() + value.slice(1)} class
                    </StyledLabel>
                  )}
                />
                <StyledIcon isOpen={isOpen}>
                  <svg
                    class="w-6 h-6 stroke-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </StyledIcon>
              </button>

              <StyledDropdown isOpen={isOpen}>
                <ul class="dropdown__options">
                  <Controller
                    control={control}
                    name={name}
                    render={({ onChange }) =>
                      tripClassItems.map((item) => (
                        <StyledOption
                          isOpen={isOpen}
                          onClick={() => {
                            onChange(item.value)
                            setIsOpen(false)
                          }}
                        >
                          <span class="dropdown__option-icon">
                            <svg
                              class="w-4 h-4 stroke-current"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5l7 7-7 7"
                              ></path>
                            </svg>
                          </span>
                          <StyledOptionLabel>{item.label}</StyledOptionLabel>
                        </StyledOption>
                      ))
                    }
                  />
                </ul>
              </StyledDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledBackdrop = styled.div.attrs({ className: 'dropdown__backdrop' })`
  ${({ theme, isOpen }) => css`
    ${isOpen &&
    css`
      opacity: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 25;
      position: absolute;
    `}
  `}
`

const StyledBackground = styled.div.attrs({
  className: 'dropdown__background'
})`
  ${({ theme, isOpen }) => css`
    ${isOpen &&
    css`
      background: #fff;
      opacity: 1;
      visibility: unset;
      transform: none;
    `}
  `}
`

const StyledDropdown = styled.div.attrs({ className: 'dropdown__list' })`
  ${({ theme, isOpen }) => css`
    ${isOpen &&
    css`
      max-height: unset;
      border-radius: 0;
    `}
  `}
`

const StyledLabel = styled.span.attrs({ className: 'dropdown__label' })`
  ${({ theme, isOpen }) => css`
    ${isOpen &&
    css`
      color: #333;
    `}
  `}
`

const StyledIcon = styled.span.attrs({ className: 'dropdown__icon' })`
  ${({ theme, isOpen }) => css`
    ${isOpen &&
    css`
      color: #333;
      transform: rotate(180deg);
    `}
  `}
`

const StyledOptionLabel = styled.span.attrs({
  className: 'dropdown__option-label'
})``

const StyledOption = styled.li.attrs({ className: 'dropdown__option' })`
  ${({ theme, isOpen }) => css`
    color: #4a94ec;
    margin: 0 10px;
    padding: 0.625rem 1rem;

    transition-property: transform, opacity, background;

    &:hover {
      background: #edf5fe;

      color: #282f36;
      border-radius: 10px;

      ${StyledOptionLabel} {
        color: #282f36;
      }
    }

    ${isOpen &&
    css`
      opacity: 1;
    `}
  `}
`
