import styled from 'styled-components'

import { breakpoints } from '../constants/breakpoints'
import { useWindowSize } from '../hooks'

export const SubmitButton = ({ submit }) => {
  const redirectPost = (url, data) => {

    const form = document.createElement('form')
    document.body.appendChild(form)
    form.method = 'post'
    form.action = url

    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'data'
    input.value = JSON.stringify(data)

    form.appendChild(input)
    form.submit()
  }

  const onClick = (data) => {
    try {
      window.localStorage.setItem('form-data', JSON.stringify(data))
    }
    finally {
      redirectPost('/offer/search/', data)
    }
  }

  const { width } = useWindowSize()
  const isMobile = width <= breakpoints.xl

  return (
    <StyledSubmitButton onClick={submit(onClick)}>
      {isMobile ? (
        `search`
      ) : (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4393 18.0607C17.0251 18.6464 17.9749 18.6464 18.5607 18.0607C19.1464 17.4749 19.1464 16.5251 18.5607 15.9393L16.4393 18.0607ZM12.4393 14.0607L16.4393 18.0607L18.5607 15.9393L14.5607 11.9393L12.4393 14.0607Z"
            fill="#F4F6F7"
          />
          <circle cx="8" cy="8" r="6.5" stroke="#F4F6F7" stroke-width="3" />
        </svg>
      )}
    </StyledSubmitButton>
  )
}

const StyledSubmitButton = styled.button.attrs({ type: 'submit' })`
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  --tw-gradient-from: #590c32;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(89, 12, 50, 0));
  --tw-gradient-to: #9d1d5a;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  height: 40px;
  height: 2.5rem;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 16px;
  line-height: 1rem;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgba(244, 246, 247, var(--tw-text-opacity));
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%;

  @media (min-width: 1280px) {
    height: 3.75rem;
    width: 3.75rem;
  }
`
